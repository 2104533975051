<template>
    <div>
        <basic-container>
            <avue-crud :option="tableOption"
                       :data="tableData"
                       :table-loading="tableLoading"
                       @current-change="currentChange"
                       @size-change="sizeChange"
                       :page.sync="page">
                    <template slot="menuLeft">
                        <el-button @click="expo" type="primary" size="small" icon="el-icon-download">导出</el-button>
                        <!-- <importBtn
                            :outApi="this.proApis.MULTIFUNCTIONALREPORT"
                        ></importBtn> -->
                    </template>
                <template slot="menuRight" slot-scop="scope">
                    <el-select v-model="type" placeholder="时间粒度" size="small" style="width:110px !important;margin-right: 5px;" @change="selType" clearable>
                        <el-option v-for="item in typeList" :key="item.value" :label="item.label" :value="item.value"></el-option>
                    </el-select>
                    <el-date-picker v-model="searchVal.strTime"
                                    value-format="yyyy-MM-dd HH:mm:ss"
                                    type="datetime"
                                    size="small"
                                    style="width:150px !important;margin-right: 5px;"
                                    placeholder="开始时间">
                    </el-date-picker>
                    <el-date-picker v-model="searchVal.endTime"
                                    value-format="yyyy-MM-dd HH:mm:ss"
                                    type="datetime"
                                    size="small"
                                    style="width:150px !important;margin-right: 5px;"
                                    placeholder="结束时间">
                    </el-date-picker>
                    <el-select v-model="chejianName" value-key="id" @change="getChejian" placeholder="车间" size="small" style="width: 110px !important; margin-right: 5px;" clearable filterable>
                        <el-option v-for="(item,index) in cjlist" :key="index" :label="item.name" :value="{name:item.name,id:item.id}"></el-option>
                    </el-select>
                    <el-select  v-model="searchVal.machineId" placeholder="设备" size="small" style="width: 110px !important; margin-right: 5px;" clearable filterable>
                        <el-option v-for="item in mechinaList" :key="item.code" :label="item.code" :value="item.id"></el-option>
                    </el-select>
                    <el-select v-model="searchVal.shiftId" placeholder="品种明细" size="small" style="width: 110px !important; margin-right: 5px;" clearable filterable>
                        <el-option v-for="item in VarietyDetailList" :key="item.name" :label="item.name" :value="item.id"></el-option>
                    </el-select>
                     <el-select v-model="searchVal.userId" placeholder="人员" size="small" style="width: 110px !important; margin-right: 5px;" clearable filterable>
                        <el-option v-for="item in corpUsersList" :key="item.userId" :label="item.name" :value="item.userId"></el-option>
                    </el-select>
                     <el-select  v-model="searchVal.productCategory" placeholder="品种分类" size="small" style="width: 110px !important; margin-right: 5px;" clearable filterable>
                        <el-option v-for="item in productCategoryList" :key="item.name" :label="item.name" :value="item.name"></el-option>
                    </el-select>
                    <el-input v-model="batchName" @blur="batchBlur" :placeholder="$t('PLEASE ENTER BATCH NUMBER')" style="display:inlie-block;width:200px;marginRight: 5px;" size="small"></el-input>
                      <!-- <el-select  v-model="searchVal.groupId" placeholder="批次" size="small" style="width: 110px !important; margin-right: 5px;" clearable>
                        <el-option v-for="item in groupList" :key="item.name" :label="item.name" :value="item.id"></el-option>
                    </el-select>  -->
                    <el-select  v-model="searchVal.groupId" placeholder="班组" size="small" style="width: 110px !important; margin-right: 5px;" clearable filterable>
                        <el-option v-for="item in groupList" :key="item.name" :label="item.name" :value="item.id"></el-option>
                    </el-select> 
                    <el-button type="primary" icon="el-icon-search" size="small" @click="search">{{$t("search")}}</el-button>
                     <div 
                     style="marginTop:20px;marginBottom:20px">
                        <el-checkbox v-model="checked1">{{$t("Product specs")}}</el-checkbox>
                        <el-checkbox v-model="checked2">{{$t("Personnel")}}</el-checkbox>
                        <el-checkbox v-model="checked3">{{$t("Product categories")}}</el-checkbox>
                        <el-checkbox v-model="checked4">{{$t("Batch")}}</el-checkbox>
                        <el-checkbox v-model="checked5">{{$t("team")}}</el-checkbox>
                    </div>
                </template>
            </avue-crud>
        </basic-container>
    </div>
</template>
<script>
    import Sortable from "sortablejs";
    import { getMechinaAll, getBZAll, getPerson, getTimp} from "@/api/config"
    import { getGrop } from '@/util/auth'

    // import importBtn from "@/components/importExport/importBtn";
    import { calssList, getList,getVarietyDetails,getproductCategory,selectIsBatchByCode,getcorpUsers,getSearch} from "@/api/formStyle"
export default {
//     components:{
//     importBtn
//   },
    data() {
        return {
             page: {
                pageSize: 10,
                pagerCount: 5,
                total: 0,
                pageCurrent: 1
            },
            tableList:[],
            tableLoading: false,
            type: "BZ",
            VarietyDetailList: [], //品种明细
            productCategoryList: [], //品种分类
            cjlist: [], //车间数组
            mechinaList: [], //设备数组
            groupList:[], // 班组数组
            corpUsersList:[], // 人员数组
            // checkedCities: [],
            batchName: '', //批次号
            chejianName: '',
            batchId:'',
            checked1: false,
            checked2: false,
            checked3: false,
            checked4: false,
            checked5: false,
            //  cities: [{flag:false,name:'设备'}
            //         ,{flag:false,name:'品种明细'}
            //         ,{flag:false,name:'人员'}
            //         ,{flag:false,name: '品种分类'}],
            // cities:[ '设备', '品种明细', '人员','品种分类','批次号','班组'],
            typeList: [
                {
                    label: "班制",
                    value: "BZ"
                },
                {
                    label: "品种",
                    value: "PZ"
                },
                {
                    label: "设备天极",
                    value: "DAY"
                },
                {
                    label: "设备小时级",
                    value: "HOUR"
                }
            ],
            timeType: true,
            userType: true,
            deviceType: true,
            timpType:true,
            searchVal: {
                data:"",
                time: "",
                strTime: '',
                endTime: '',
                workShopId: "",
                machineId: "",
                groupId: "",
                userId: "",
                shiftId:"",
                productCategory:""
            },
            tableData: [],
            tableOption: {
                align: "center",
                cellBtn: false,
                refreshBtn: false,
                columnBtn: false,
                addBtn: false,
                menu:false,
                column: [
                {
                    label: '车间',
                    prop: 'workShopName'
                },
                {
                    label: '产量',
                    prop: 'yield'
                },
                {
                    label: '能耗',
                    prop: 'energy'
                },
                {
                    label: '设备',
                    prop: 'machineName'
                },
                {
                    label: '品种明细',
                    hide: true,
                    prop: 'productName'
                },
                {
                    label: '人员',
                    hide: true,
                    prop: 'userName'
                },
                {
                    label: '品种分类',
                    hide: true,
                    prop: 'productCategoryName'
                },
                {
                    label: '批次号',
                    hide: true,
                    prop: 'batchCode'
                },
                {
                    label: '班组',
                    hide: true,
                    prop: 'groupName'
                },
            ],
            },
        };
    },
    created() {
      if (this.$route.query.type){
        console.log(this.$route.query);
        let type = this.$route.query.type;
        switch (type){
          case 'user': this.searchVal.userId = this.$route.query.userId;
                       this.searchVal.groupId = this.$route.query.groupId;
                       break;
          case 'device': this.searchVal.workShopId = this.$route.query.departmentId;
                         this.searchVal.machineId = this.$route.query.id;
                         break;
          case 'timp': this.searchVal.groupId = this.$route.query.id; break;
        }
      }
        this.getTimeFn()
         //获取车间
        this.getCJ()
         //获取设备
        this.getMec()
        // 获取班组
        this.getGroup()
        //品种明细列表
        getVarietyDetails().then(res => {
            this.VarietyDetailList = res.data.data
            console.log(res);
        }),
        // 品种分类列表
        getproductCategory().then(res => {
            this.productCategoryList = res.data.data
            console.log('品种分类',res);
        }),
        getcorpUsers().then(res => {
            console.log('人员列表',res);
            this.corpUsersList = res.data.data
        })
    },
    methods: {
        async expo() {
            window.location.href = this.proApis.MULTIFUNCTIONALREPORT + '?strTime=' + this.searchVal.strTime + 
                                                                                    '&endTime=' + this.searchVal.endTime + 
                                                                                    '&granularity=' + this.type + 
                                                                                    '&workShopId=' + this.searchVal.workShopId + 
                                                                                    '&workShopName=' + this.chejianName + 
                                                                                    '&machineId=' + this.searchVal.machineId + 
                                                                                    '&groupId=' + this.searchVal.groupId + 
                                                                                    '&productId=' + this.searchVal.shiftId + 
                                                                                    '&productCategoryId=' + this.searchVal.groupId + 
                                                                                    '&userId=' + this.searchVal.userId + 
                                                                                    '&batchId=' + this.batchId + 
                                                                                    '&isGroup=' + this.checked5 + 
                                                                                    '&isUser=' + this.checked2 + 
                                                                                    '&isProductCategory=' + this.checked3 + 
                                                                                    '&isProduct=' + this.checked1 + 
                                                                                    '&isBatch=' + this.checked4 + 
                                                                                    '&corporationId=' +getGrop() 
        },
        init() {
            console.log('==========');
            // this.totalPage = Math.ceil(this.tableList.length / this.page.pageSize);
            // this.totalPage = this.totalPage == 0 ? 1 : this.totalPage;
            let begin = (this.page.pageCurrent - 1) * this.page.pageSize;
            let end = this.page.pageCurrent * this.page.pageSize;
            this.tableData = this.tableList.slice(begin,end);
            this.$forceUpdate()
        },
         sizeChange(val) {
             console.log(val);
             this.page.pageSize = val
            // this.page.currentPage = 1
            // this.page.pageSize = val
            this.init()
            // this.$message.success('行数' + val)
      },
      currentChange(val) {
          console.log(val);
          this.page.pageCurrent = val
            this.init()
      },
        //获取车间
        getCJ() {
            calssList().then(res => {
                this.cjlist = res.data.data;
                if (this.searchVal.workShopId){
                  this.cjlist.forEach(item =>{
                    if (item.id == this.searchVal.workShopId){
                      this.chejianName = item.name;
                    }
                  })
                }else {
                  this.chejianName = this.cjlist[0].name;
                  this.searchVal.workShopId = this.cjlist[0].id;
                }
              this.search()
            })
        },
        //获取设备
        getMec() {
            getMechinaAll().then(res => {
                this.mechinaList = res.data.data;
            })
        },
        selType() {
            console.log('111');
        },
        //获取班组
        getGroup() {
            getBZAll().then(res => {
                this.groupList = res.data.data;
            })
        },
        // 批号失焦事件
        batchBlur() {
            console.log(this.batchName);
            selectIsBatchByCode(this.batchName).then(res => {
                console.log('res',res);
                if (res.data.data.flag == false) {
                    this.batchId = ''
                    this.$message({
                        showClose: true,
                        message: "批号不存在",
                        type: "error"
                    });
                }else {
                   this.batchId = res.data.data.batchId
                }
            })
        },
        //搜索
        search() {
            console.log(this.tableOption.column[4]);
            if(this.checked1 == true) {
                this.tableOption.column[4].hide = false
            } else {
                this.tableOption.column[4].hide = true
            }
            if(this.checked2 == true) {
                 this.tableOption.column[5].hide = false
            }else {
                this.tableOption.column[5].hide = true
            }
            if(this.checked3 == true) {
                 this.tableOption.column[6].hide = false
            } else {
                 this.tableOption.column[6].hide = true
            }
            if(this.checked4 == true) {
                 this.tableOption.column[7].hide = false
            }else {
                 this.tableOption.column[7].hide = true
            }
            if(this.checked5 == true) {
                 this.tableOption.column[8].hide = false
            }else {
                 this.tableOption.column[8].hide = true
            }
            console.log(this.checked,this.checked1);
            let obj = {
                strTime: this.searchVal.strTime,
                endTime: this.searchVal.endTime,
                granularity:this.type,
                workShopId:this.searchVal.workShopId,
                workShopName:this.chejianName,
                machineId:this.searchVal.machineId,
                groupId:this.searchVal.groupId,
                productId:this.searchVal.shiftId,
                productCategoryId:this.searchVal.groupId,
                userId:this.searchVal.userId,
                batchId:this.batchId,
                isGroup:this.checked5,
                isUser:this.checked2,
                isProductCategory:this.checked3,
                isProduct:this.checked1,
                isBatch:this.checked4
            }
            if (this.type == '' || this.searchVal.strTime == '' || this.searchVal.endTime == '' || this.searchVal.workShopId == '') {
                this.$message({
                        showClose: true,
                        message: "时间粒度，开始时间，结束时间，车间不能为空",
                        type: "error"
                    });
            } else {
                getSearch(obj).then(res => {
                    console.log('点击查询',res);
                    this.page.total = res.data.data.data.length
                    this.tableList = res.data.data.data
                    this.init()
                    console.log('11',this.tableData);
                })
            }
            console.log(this.type);
        },
        handleChange(row) {
            console.log('1111',row);
        },
        getChejian(e) {
            this.chejianName = e.name
            this.searchVal.workShopId = e.id
            console.log(e);
        },
          getTimeFn() {
          const end = new Date();
          const start = new Date();
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 1);
          this.searchVal.strTime = this.formatDate(start);
          this.searchVal.endTime = this.formatDate(end);
      },
        formatDate(date) {
          var myyear = date.getFullYear();
          var mymonth = date.getMonth() + 1;
          var myweekday = date.getDate();
          var hTime = date.getHours();
          var Ftime = date.getMinutes();
          var sTime = date.getSeconds();

          if (mymonth < 10) {
            mymonth = "0" + mymonth;
          }
          if (myweekday < 10) {
            myweekday = "0" + myweekday;
          }
           if (hTime < 10) {
                hTime = "0" + hTime;
            }
            if (Ftime < 10) {
                Ftime = "0" + Ftime;
            }
            if (sTime < 10) {
                sTime = "0" + sTime;
            }
          return myyear + "-" + mymonth + "-" + myweekday + " "+ hTime + ":" + Ftime + ":" + sTime;
        },
        list() {
            // console.log(this.page.currentPage, this.page.pageSize, this.searchVal)
            // getList(this.page.currentPage, this.page.pageSize,this.searchVal).then(res => {
            //     console.log(res);
            //     if (res.data.data !== null) {
            //         this.tableData = res.data.data.data;
            //         this.page.total = res.data.data.total;
            //     } else {
            //         this.tableData = [];
            //     }
            // })
        },
        //每个多少条
        // sizeChange(v) {
        //     console.log(v)
        //     this.page.pageSize = v;
        //     this.list();
        // },
        //当前第几页
        // currentChange(v) {
        //     console.log(v)
        //     this.page.currentPage = v;
        //     this.list();
        // },
        // setDate(d) {
        //     var date = new Date(d); //日期对象
        //     var yTime = date.getFullYear();
        //     var MTime = date.getMonth() + 1;
        //     var dTime = date.getDate();
        //     var hTime = date.getHours();
        //     var Ftime = date.getMinutes();
        //     var sTime = date.getSeconds();
        //     if (MTime < 10) {
        //         MTime = "0" + MTime;
        //     }
        //     if (dTime < 10) {
        //         dTime = "0" + dTime;
        //     }
        //     if (hTime < 10) {
        //         hTime = "0" + hTime;
        //     }
        //     if (Ftime < 10) {
        //         Ftime = "0" + Ftime;
        //     }
        //     if (sTime < 10) {
        //         sTime = "0" + sTime;
        //     }
        //     return yTime + "-" + MTime + "-" + dTime + " " + hTime + ":" + Ftime + ":" + sTime;
        // }
    },
};
</script>
