var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "basic-container",
        [
          _c(
            "avue-crud",
            {
              attrs: {
                option: _vm.tableOption,
                data: _vm.tableData,
                "table-loading": _vm.tableLoading,
                page: _vm.page
              },
              on: {
                "current-change": _vm.currentChange,
                "size-change": _vm.sizeChange,
                "update:page": function($event) {
                  _vm.page = $event
                }
              }
            },
            [
              _c(
                "template",
                { slot: "menuLeft" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: "primary",
                        size: "small",
                        icon: "el-icon-download"
                      },
                      on: { click: _vm.expo }
                    },
                    [_vm._v("导出")]
                  )
                ],
                1
              ),
              _c(
                "template",
                { attrs: { "slot-scop": "scope" }, slot: "menuRight" },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: {
                        width: "110px !important",
                        "margin-right": "5px"
                      },
                      attrs: {
                        placeholder: "时间粒度",
                        size: "small",
                        clearable: ""
                      },
                      on: { change: _vm.selType },
                      model: {
                        value: _vm.type,
                        callback: function($$v) {
                          _vm.type = $$v
                        },
                        expression: "type"
                      }
                    },
                    _vm._l(_vm.typeList, function(item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value }
                      })
                    }),
                    1
                  ),
                  _c("el-date-picker", {
                    staticStyle: {
                      width: "150px !important",
                      "margin-right": "5px"
                    },
                    attrs: {
                      "value-format": "yyyy-MM-dd HH:mm:ss",
                      type: "datetime",
                      size: "small",
                      placeholder: "开始时间"
                    },
                    model: {
                      value: _vm.searchVal.strTime,
                      callback: function($$v) {
                        _vm.$set(_vm.searchVal, "strTime", $$v)
                      },
                      expression: "searchVal.strTime"
                    }
                  }),
                  _c("el-date-picker", {
                    staticStyle: {
                      width: "150px !important",
                      "margin-right": "5px"
                    },
                    attrs: {
                      "value-format": "yyyy-MM-dd HH:mm:ss",
                      type: "datetime",
                      size: "small",
                      placeholder: "结束时间"
                    },
                    model: {
                      value: _vm.searchVal.endTime,
                      callback: function($$v) {
                        _vm.$set(_vm.searchVal, "endTime", $$v)
                      },
                      expression: "searchVal.endTime"
                    }
                  }),
                  _c(
                    "el-select",
                    {
                      staticStyle: {
                        width: "110px !important",
                        "margin-right": "5px"
                      },
                      attrs: {
                        "value-key": "id",
                        placeholder: "车间",
                        size: "small",
                        clearable: "",
                        filterable: ""
                      },
                      on: { change: _vm.getChejian },
                      model: {
                        value: _vm.chejianName,
                        callback: function($$v) {
                          _vm.chejianName = $$v
                        },
                        expression: "chejianName"
                      }
                    },
                    _vm._l(_vm.cjlist, function(item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: {
                          label: item.name,
                          value: { name: item.name, id: item.id }
                        }
                      })
                    }),
                    1
                  ),
                  _c(
                    "el-select",
                    {
                      staticStyle: {
                        width: "110px !important",
                        "margin-right": "5px"
                      },
                      attrs: {
                        placeholder: "设备",
                        size: "small",
                        clearable: "",
                        filterable: ""
                      },
                      model: {
                        value: _vm.searchVal.machineId,
                        callback: function($$v) {
                          _vm.$set(_vm.searchVal, "machineId", $$v)
                        },
                        expression: "searchVal.machineId"
                      }
                    },
                    _vm._l(_vm.mechinaList, function(item) {
                      return _c("el-option", {
                        key: item.code,
                        attrs: { label: item.code, value: item.id }
                      })
                    }),
                    1
                  ),
                  _c(
                    "el-select",
                    {
                      staticStyle: {
                        width: "110px !important",
                        "margin-right": "5px"
                      },
                      attrs: {
                        placeholder: "品种明细",
                        size: "small",
                        clearable: "",
                        filterable: ""
                      },
                      model: {
                        value: _vm.searchVal.shiftId,
                        callback: function($$v) {
                          _vm.$set(_vm.searchVal, "shiftId", $$v)
                        },
                        expression: "searchVal.shiftId"
                      }
                    },
                    _vm._l(_vm.VarietyDetailList, function(item) {
                      return _c("el-option", {
                        key: item.name,
                        attrs: { label: item.name, value: item.id }
                      })
                    }),
                    1
                  ),
                  _c(
                    "el-select",
                    {
                      staticStyle: {
                        width: "110px !important",
                        "margin-right": "5px"
                      },
                      attrs: {
                        placeholder: "人员",
                        size: "small",
                        clearable: "",
                        filterable: ""
                      },
                      model: {
                        value: _vm.searchVal.userId,
                        callback: function($$v) {
                          _vm.$set(_vm.searchVal, "userId", $$v)
                        },
                        expression: "searchVal.userId"
                      }
                    },
                    _vm._l(_vm.corpUsersList, function(item) {
                      return _c("el-option", {
                        key: item.userId,
                        attrs: { label: item.name, value: item.userId }
                      })
                    }),
                    1
                  ),
                  _c(
                    "el-select",
                    {
                      staticStyle: {
                        width: "110px !important",
                        "margin-right": "5px"
                      },
                      attrs: {
                        placeholder: "品种分类",
                        size: "small",
                        clearable: "",
                        filterable: ""
                      },
                      model: {
                        value: _vm.searchVal.productCategory,
                        callback: function($$v) {
                          _vm.$set(_vm.searchVal, "productCategory", $$v)
                        },
                        expression: "searchVal.productCategory"
                      }
                    },
                    _vm._l(_vm.productCategoryList, function(item) {
                      return _c("el-option", {
                        key: item.name,
                        attrs: { label: item.name, value: item.name }
                      })
                    }),
                    1
                  ),
                  _c("el-input", {
                    staticStyle: {
                      display: "inlie-block",
                      width: "200px",
                      marginRight: "5px"
                    },
                    attrs: {
                      placeholder: _vm.$t("PLEASE ENTER BATCH NUMBER"),
                      size: "small"
                    },
                    on: { blur: _vm.batchBlur },
                    model: {
                      value: _vm.batchName,
                      callback: function($$v) {
                        _vm.batchName = $$v
                      },
                      expression: "batchName"
                    }
                  }),
                  _c(
                    "el-select",
                    {
                      staticStyle: {
                        width: "110px !important",
                        "margin-right": "5px"
                      },
                      attrs: {
                        placeholder: "班组",
                        size: "small",
                        clearable: "",
                        filterable: ""
                      },
                      model: {
                        value: _vm.searchVal.groupId,
                        callback: function($$v) {
                          _vm.$set(_vm.searchVal, "groupId", $$v)
                        },
                        expression: "searchVal.groupId"
                      }
                    },
                    _vm._l(_vm.groupList, function(item) {
                      return _c("el-option", {
                        key: item.name,
                        attrs: { label: item.name, value: item.id }
                      })
                    }),
                    1
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: "primary",
                        icon: "el-icon-search",
                        size: "small"
                      },
                      on: { click: _vm.search }
                    },
                    [_vm._v(_vm._s(_vm.$t("search")))]
                  ),
                  _c(
                    "div",
                    {
                      staticStyle: { marginTop: "20px", marginBottom: "20px" }
                    },
                    [
                      _c(
                        "el-checkbox",
                        {
                          model: {
                            value: _vm.checked1,
                            callback: function($$v) {
                              _vm.checked1 = $$v
                            },
                            expression: "checked1"
                          }
                        },
                        [_vm._v(_vm._s(_vm.$t("Product specs")))]
                      ),
                      _c(
                        "el-checkbox",
                        {
                          model: {
                            value: _vm.checked2,
                            callback: function($$v) {
                              _vm.checked2 = $$v
                            },
                            expression: "checked2"
                          }
                        },
                        [_vm._v(_vm._s(_vm.$t("Personnel")))]
                      ),
                      _c(
                        "el-checkbox",
                        {
                          model: {
                            value: _vm.checked3,
                            callback: function($$v) {
                              _vm.checked3 = $$v
                            },
                            expression: "checked3"
                          }
                        },
                        [_vm._v(_vm._s(_vm.$t("Product categories")))]
                      ),
                      _c(
                        "el-checkbox",
                        {
                          model: {
                            value: _vm.checked4,
                            callback: function($$v) {
                              _vm.checked4 = $$v
                            },
                            expression: "checked4"
                          }
                        },
                        [_vm._v(_vm._s(_vm.$t("Batch")))]
                      ),
                      _c(
                        "el-checkbox",
                        {
                          model: {
                            value: _vm.checked5,
                            callback: function($$v) {
                              _vm.checked5 = $$v
                            },
                            expression: "checked5"
                          }
                        },
                        [_vm._v(_vm._s(_vm.$t("team")))]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }